@import '~antd/dist/antd.css';

#dynamic_roles {
  display: flex;
  flex-direction: column;
}

*{
  border-style: none;
}
